<template>
  <a-modal
    class="sticker-edit-modal"
    title="Редактировать стикер"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="update"
    @cancel="resetForm"
  >
    <a-form :model="sticker">
      <div class="col-md-12">
        <a-upload
          name="icon"
          list-type="picture-card"
          class="logo-uploader"
          :show-upload-list="false"
          :headers="getHeaders()"
          :action="getUrl"
          :before-upload="beforeUpload"
          @change="uploadImage"
        >
          <img v-if="iconUrl" :src="iconUrl" alt="avatar" />
          <div v-else>
            <loading-outlined v-if="loading"></loading-outlined>
            <PlusOutlined v-else></PlusOutlined>
            <div class="ant-upload-text">Логотип</div>
          </div>
        </a-upload>
        <div class="mb-4 text-center" v-if="iconUrl">
          <a-button v-if="false" @click="removeImage" type="link" danger><CloseCircleOutlined />Удалить фотографию</a-button>
        </div>
        <a-form-item label="Эмодзи">
          <a-input
            placeholder="Введите эмодзи стикера"
            v-model:value="sticker.emoji"
          />
        </a-form-item>
        <a-form-item label="Название стикера">
          <a-input
            ref="stickerName"
            placeholder="Введите название стикера"
            v-model:value="sticker.text"
          />
        </a-form-item>
        <a-form-item label="Тип стикера">
          <a-select show-search v-model:value="sticker.type">
            <a-select-option :value="1">Сотрудник</a-select-option>
            <a-select-option :value="2">Заведение</a-select-option>
            <a-select-option :value="3">Доставка</a-select-option>
          </a-select>
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import { PlusOutlined, LoadingOutlined, CloseCircleOutlined } from '@ant-design/icons-vue';
import {ref, reactive, toRaw, onMounted, nextTick} from 'vue';
import { getHeaders } from "@/helpers/header"
import { getBase64 } from "@/helpers/base64";
import { message } from "ant-design-vue";
import apiClient from "@/services/axios";
import Sticker from "@/models/Sticker";

const props = defineProps({
  stickerId: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['hide-modal', 'reload']);

let iconUrl = ref(''),
  loading = ref(true),
  confirmLoading = ref(false),
  modalVisible = ref(true),
  stickerName = ref("stickerName"),
  initialSticker = new Sticker({}),
  sticker = reactive(initialSticker);

const
  uploadImage = info => {
    iconUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      sticker.icon = info.file.response.data
      getBase64(info.file.originFileObj, base64Url => {
        iconUrl.value = base64Url;
        loading.value = false;
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  removeImage = () => {
    iconUrl.value = ''
    sticker.icon = ''

    confirmLoading.value = true;
    apiClient
      .put(`/sticker/update?id=${props.stickerId}`, { icon: '' })
      .then(response => {
        if (response) {
          confirmLoading.value = false;
        }
      }).catch(err => {
      confirmLoading.value = false;
    }).finally(() => {
      confirmLoading.value = false;
    })
  },
  beforeUpload = file => {
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';

    // if (!isJpgOrPngOrSvg) {
    //   message.error('You can only upload JPG, PNG or SVG file!');
    // }

    const isLt2M = file.size / 1024 / 1024 < 2;

    // if (!isLt2M) {
    //   message.error('Image must smaller than 2MB!');
    // }

    return isJpgOrPngOrSvg && isLt2M;
  },
  getUrl = () => {
    return process.env.VUE_APP_BACKEND_URL + '/cabinet/sticker/upload'
  },
  resetForm = () => {
    Object.assign(sticker, new Sticker({}));
    modalVisible.value = false;
    emit('hide-modal')
  },
  update = () => {
    confirmLoading.value = true;

    let model = toRaw(sticker)

    return apiClient.put(`sticker/update?id=${props.stickerId}`, model).then(response => {
      if (response) {
        resetForm()
        modalVisible.value = false;
        confirmLoading.value = false;
        emit('reload')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      confirmLoading.value = false;
      modalVisible.value = false;
      emit('reload')
      emit('hide-modal')
    })
  };

apiClient.get(`/sticker/view?id=${props.stickerId}`).then(response => {
  if (response) {
    iconUrl.value = response.data.data.icon
    let model = new Sticker(response.data.data)
    Object.assign(sticker, model)
  }
}).catch(() => {
  // console.log(err)
}).finally(() => {
  loading.value = false
})

onMounted(() => nextTick(() => stickerName.value?.focus()))
</script>

<style scoped>
@import "./edit.css";
</style>
